<template>
  <div class="d-flex flex-column flex-fill white pb-5">
    <h1 class="text-center">Recibo de Fechamento de Caixa</h1>
    <h4 class="text-center my-5">Fisiopower</h4>
    <div class="d-flex justify-center my-5">
      <table>
        <tr>
          <th>Nº Caixa</th>
          <td>{{ close.id }}</td>
        </tr>
        <tr>
          <th>Data e Hora</th>
          <td>{{ close.date | moment("DD/MM/YYYY HH:mm:ss") }}</td>
        </tr>
        <tr>
          <th>Total Dinheiro</th>
          <td>R$ {{ close.value_money | money }}</td>
        </tr>
        <tr>
          <th>Total Cartão</th>
          <td>R$ {{ close.value_card | money }}</td>
        </tr>
        <tr>
          <th>Total Cheque</th>
          <td>R$ {{ close.value_check | money }}</td>
        </tr>
        <tr>
          <th>Total Vendas</th>
          <td>R$ 0,00</td>
        </tr>
      </table>
    </div>
    <div class="signature mt-15">
      <span>{{ close.seller.first_name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CloseBox",
  data() {
    return {
      close: null,
    };
  },
  beforeMount() {
    this.close = this.closeSession;
  },
  computed: {
    closeSession() {
      const close = sessionStorage.getItem("transfer");
      sessionStorage.removeItem("transfer");
      return close ? JSON.parse(close) : null;
    },
  },
};
</script>

<style scoped>
table {
  width: 300px;
}
th {
  width: 120px;
}
td {
  text-align: right;
}
table,
th,
td {
  border-spacing: 0;
  border: 1px solid #333;
}

.signature {
  display: flex;
  justify-content: center;
  text-align: center;
}

.signature span {
  width: 300px;
  border-top: 1px solid #000;
}
</style>
